// 所有的请求都放在该目录
import instance from "../utils/request";

export default{
    fileUpload(params) {
        return instance({
            url: '/fileUpload',
            method: 'post',
            data: params
        })
    },
    getHomeData(params) {
        return instance({
            url: '/index/home',
            method: 'get',
            data: params
        })
    },
    noticeDetail(id) {
        return instance({
            url: `/index/detail/${id}`,
            method: 'get',
        })
    },
    listPageAnnouncement(params) {
        return instance({
            url: '/index/listPageAnnouncement',
            method: 'post',
            data: params
        })
    },
    loginPwd(params) {
        return instance({
            url: '/loginPwd',
            method: 'post',
            data: params
        })
    },
    register(params) {
        return instance({
            url: '/register',
            method: 'post',
            data: params
        })
    },
    findPwd(params) {
        return instance({
            url: '/findPwd',
            method: 'post',
            data: params
        })
    },
    loginCode(params) {
        return instance({
            url: '/loginCode',
            method: 'post',
            data: params
        })
    },
    sendSms(params) {
        return instance({
            url: '/sendSms',
            method: 'post',
            data: params
        })
    },
    protocol(config) {
        return instance({
            url: `/protocol/config/${config}`,
            method: 'get',
        })
    },
    addOrUpdateUserAddress(params) {
        return instance({
            url: '/user/addOrUpdateUserAddress',
            method: 'post',
            data: params
        })
    },
    cashRewardList(params) {
        return instance({
            url: '/user/cashRewardList',
            method: 'post',
            data: params
        })
    },
    cashRewardTotal(params) {
        return instance({
            url: '/user/cashRewardTotal',
            method: 'post',
            data: params
        })
    },
    myFansLst(params) {
        return instance({
            url: '/user/myFansLst',
            method: 'post',
            data: params
        })
    },
    myCommissionList(params) {
        return instance({
            url: '/user/myCommissionList',
            method: 'post',
            data: params
        })
    },
    teamData(params) {
        return instance({
            url: '/user/teamData',
            method: 'post',
            data: params
        })
    },
    saveSignResorce(params) {
        return instance({
            url: '/user/saveSignResorce',
            method: 'post',
            data: params
        })
    },
    teamCommissionList(params) {
        return instance({
            url: '/user/teamCommissionList',
            method: 'post',
            data: params
        })
    },
    updateInfo(params) {
        return instance({
            url: '/user/updateInfo',
            method: 'post',
            data: params
        })
    },
    updatePayInfo(params) {
        return instance({
            url: '/user/updatePayInfo',
            method: 'post',
            data: params
        })
    },
    applyDraw(params) {
        return instance({
            url: '/user/applyDraw',
            method: 'post',
            data: params
        })
    },
    userApply(params) {
        return instance({
            url: '/user/userApply',
            method: 'post',
            data: params
        })
    },
    orderList(params) {
        return instance({
            url: '/user/orderList',
            method: 'post',
            data: params
        })
    },
    payInfo(params) {
        return instance({
            url: '/user/payInfo',
            method: 'get',
        })
    },
    qrySignResorce(params) {
        return instance({
            url: '/user/qrySignResorce',
            method: 'get',
        })
    },
    userAddress() {
        return instance({
            url: '/user/userAddress',
            method: 'get',
        })
    },
    userInfo(params) {
        return instance({
            url: '/user/userInfo',
            method: 'get',
        })
    },
    couponList(state) {
        return instance({
            url: `/user/couponList/${state}`,
            method: 'get',
        })
    },
    deleteUserAddress(id) {
        return instance({
            url: `/user/deleteUserAddress/${id}`,
            method: 'get',
        })
    },
    sessionList() {
        return instance({
            url: `/session/list`,
            method: 'get',
        })
    },
    sessionGoodList(params) {
        return instance({
            url: '/session/sessionGoodList',
            method: 'post',
            data: params
        })
    },
    createEntrustOrder(params) {
        return instance({
            url: '/session/createEntrustOrder',
            method: 'post',
            data: params
        })
    },
    sessionGoodDetail(id) {
        return instance({
            url: `/session/sessionGoodDetail/${id}`,
            method: 'get',
        })
    },
    waitEntrustOrder(id) {
        return instance({
            url: `/session/waitEntrustOrder/${id}`,
            method: 'get',
        })
    },
    entrustOrderDetail(id) {
        return instance({
            url: `/session/entrustOrderDetail/${id}`,
            method: 'get',
        })
    },
    buySessionGood(id) {
        return instance({
            url: `/session/buySessionGood/${id}`,
            method: 'get',
        })
    },
    qryUserOpenId(params) {
        return instance({
            url: '/session/qryUserOpenId',
            method: 'post',
            data: params
        })
    },
    payOrder(params) {
        return instance({
            url: '/session/payOrder',
            method: 'post',
            data: params
        })
    },
    buyGoodList(params) {
        return instance({
            url: '/session/buyGoodList',
            method: 'post',
            data: params
        })
    },
    sellGoodList(params) {
        return instance({
            url: '/session/sellGoodList',
            method: 'post',
            data: params
        })
    },
    uploadPaymentInfo(params) {
        return instance({
            url: '/session/uploadPaymentInfo',
            method: 'post',
            data: params
        })
    },
    qrySignResorce() {
        return instance({
            url: '/user/qrySignResorce',
            method: 'get',
        })
    },
    buySessionGoodList(sessionRecordId) {
        return instance({
            url: `/session/buySessionGoodList/${sessionRecordId}`,
            method: 'get',
        })
    },
    sellSessionGood(sessionRecordId) {
        return instance({
            url: `/session/sellSessionGood/${sessionRecordId}`,
            method: 'get',
        })
    },
    cannelEntrustOrder(orderNo) {
        return instance({
            url: `/session/cannelEntrustOrder/${orderNo}`,
            method: 'get',
        })
    },
    submitPickingGoods(params) {
        return instance({
            url: `/session/submitPickingGoods`,
            method: 'post',
            data:params
        })
    },
    pickingGoodsDetail(id) {
        return instance({
            url: `/session/pickingGoodsDetail/${id}`,
            method: 'get',
        })
    },
    confirmPayment(params) {
        return instance({
            url: '/session/confirmPayment',
            method: 'post',
            data: params
        })
    },
    teamSessionRecord(id,isParticipate) {
        return instance({
            url: `/user/teamSessionRecord/${id}/${isParticipate}`,
            method: 'get',
        })
    },
    remarkList() {
        return instance({
            url: `/user/remarkList`,
            method: 'get',
        })
    },
    addRemarkList(params) {
        return instance({
            url: '/user/addRemarkList',
            method: 'post',
            data: params
        })
    },
    updateTeamUserRemark(params) {
        return instance({
            url: '/user/updateTeamUserRemark',
            method: 'post',
            data: params
        })
    },
} 