import Vue from 'vue'
import Vuex from 'vuex'
import {getToken} from '@/utils/token.js'
import api from "@/https/http.js";
Vue.use(Vuex)

const state={
	token:getToken(),
	homeData:{},
	userName:{},
	buyList:[]
};
const getters={
	token:state=>state.token,
	userName:state=>state.userName,
	homeData:state=>state.homeData,
	buyList:state=>state.buyList
};
const actions={
	login({commit,state},userInfo){
		return new Promise((resolve,reject)=>{
			api.login({account: userInfo.account,password: userInfo.password}).then(res=>{
				if(res.data.code==1){
					commit('SET_TOKEN',res.data.data)
					resolve(res.data.data)
				}
			}).catch(error=>{
				reject(error)
			})
		})
	},
	getInfo({commit,state}){
		return new Promise((resolve,reject)=>{
			api.userInfo().then(res=>{
				if(res.data.code==1){
					commit('SET_USERNAME',res.data.data)
				}
				resolve(res.data.data)
			}).catch(error=>{
				reject(error)
			})
		})
	},
	getHomeData({commit,state}){
		return new Promise((resolve,reject)=>{
			api.getHomeData({}).then(res=>{
				if(res.data.code==1){
					commit('SET_HOMEDATA',res.data.data)
				}
				resolve(res.data.data)
			}).catch(error=>{
				reject(error)
			})
		})
	},
	getBuyList({commit,state}){
		return new Promise((resolve,reject)=>{
			api.sessionList().then(res=>{
				if(res.data.code==1){
					commit('SET_BUYLIST',res.data.data)
				}
				resolve(res.data.data)
			}).catch(error=>{
				reject(error)
			})
		})
	},
	remove({commit,state}){
		return new Promise((resolve,reject)=>{
			commit('SET_TOKEN', '')
			commit('SET_USERNAME', {})
			resolve('succase')
		})
	}
};
const mutations={
	SET_HOMEDATA:(state,homeData)=>{
		state.homeData=homeData;
	},
	SET_BUYLIST:(state,buyList)=>{
		state.buyList=buyList;
	},
	SET_TOKEN:(state,token)=>{
		state.token=token;
	},
	SET_USERNAME:(state,userName)=>{
		state.userName=userName;
	}
};
export default{
	state,
	getters,
	actions,
	mutations
}