<template>
  <div class="app-tab-bar">
    <!-- 下方导航 -->
    
    <van-tabbar
      v-model="active"
      active-color="#ee0a24"
      inactive-color="#000"
      @change="onChange"
      shape="round"
      route
    >
      <van-tabbar-item icon="home-o" to="/home">首页</van-tabbar-item>
      <van-tabbar-item icon="shopping-cart-o" to="/buy">抢购</van-tabbar-item>
      <van-tabbar-item icon="wap-home-o" to="/warehouse">仓库</van-tabbar-item>
      <van-tabbar-item icon="user-o" to="/user">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: "app-tab-bar",
  data() {
    return {
      active: 0
    };
  },
  methods: {
    onChange(m) {
      this.active = m;
    }
  }
};
</script>

<style scoped>
</style>