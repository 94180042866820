import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/reset.css'
import 'lib-flexible/flexible'
//导入所有组件。
import Vant from 'vant';
import 'vant/lib/index.css';
import { Lazyload } from 'vant';
import { Toast } from 'vant';
import SignCanvas from "sign-canvas";
Vue.use(SignCanvas);

Vue.use(Toast);
Vue.use(Lazyload);
Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.Toast = Toast

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
