import Vue from 'vue'
import VueRouter from 'vue-router'
import {getToken} from '@/utils/token.js'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/home'
  },
  {
    path: '/home', 
    name: 'Home',
    component: () => import('@/views/home/index'),
    children: [ // 二级路由 不能加'/' 加'/'表示一级路由
      // {
      //   path: 'searchPopup',
      //   component: () => import('@/views/home/search/searchPopup.vue'),
      //   name: 'searchpopup',
      //   meta: {
      //     isshowtabbar: false
      //   },


      // }
    ],
    meta: {
      isShowTabbar: true
    }
  },
  {
    path: '/warehouse', 
    name: 'Warehouse',
    component: () => import('@/views/warehouse/index'),
    meta: {
      isShowTabbar: true
    }
  },
  {
    path: '/buy', 
    name: 'Buy',
    component: () => import('@/views/buy/index'),
    meta: {
      isShowTabbar: true
    }
  },
  {
    path: '/user', 
    name: 'User',
    component: () => import('@/views/user/index'),
    meta: {
      isShowTabbar: true
    }
  },
  {
    path: '/login', 
    name: 'Login',
    component: () => import('@/views/login/index'),
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/htmlTxt', 
    name: 'HtmlTxt',
    component: () => import('@/views/htmlTxt/index'),
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/setting', 
    name: 'Setting',
    component: () => import('@/views/setting/index'),
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/userInfo', 
    name: 'UserInfo',
    component: () => import('@/views/userInfo/index'),
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/myOder', 
    name: 'MyOder',
    component: () => import('@/views/myOder/index'),
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/pageCaptureInfo', 
    name: 'PageCaptureInfo',
    component: () => import('@/views/pageCaptureInfo/index'),
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/pageSettlement', 
    name: 'PageSettlement',
    component: () => import('@/views/pageSettlement/index'),
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/shopDetail', 
    name: 'ShopDetail',
    component: () => import('@/views/shopDetail/index'),
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/couponList', 
    name: 'CouponList',
    component: () => import('@/views/couponList/index'),
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/myAddress', 
    name: 'MyAddress',
    component: () => import('@/views/myAddress/index'),
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/addressDetail', 
    name: 'AddressDetail',
    component: () => import('@/views/addressDetail/index'),
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/collection', 
    name: 'Collection',
    component: () => import('@/views/collection/index'),
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/signing', 
    name: 'Signing',
    component: () => import('@/views/signing/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/warehouseDetail', 
    name: 'WarehouseDetail',
    component: () => import('@/views/warehouseDetail/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/listing', 
    name: 'Listing',
    component: () => import('@/views/listing/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/picking', 
    name: 'Picking',
    component: () => import('@/views/picking/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/toBePaid', 
    name: 'ToBePaid',
    component: () => import('@/views/toBePaid/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/shareQrcode', 
    name: 'ShareQrcode',
    component: () => import('@/views/shareQrcode/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/noticeList', 
    name: 'NoticeList',
    component: () => import('@/views/noticeList/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/noticeDetail', 
    name: 'NoticeDetail',
    component: () => import('@/views/noticeDetail/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  
  {
    path: '/distribution',  //分销
    name: 'Distribution',
    component: () => import('@/views/distribution/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/withdrawal', //提现
    name: 'Withdrawal',
    component: () => import('@/views/withdrawal/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/withdrawalRecords', //提现记录
    name: 'WithdrawalRecords',
    component: () => import('@/views/withdrawalRecords/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/commission', //佣金
    name: 'Commission',
    component: () => import('@/views/commission/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/fans', //粉丝 
    name: 'Fans',
    component: () => import('@/views/fans/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/teamList', //团队订单 
    name: 'TeamList',
    component: () => import('@/views/teamList/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/myProfit', //我的利润
    name: 'MyProfit',
    component: () => import('@/views/myProfit/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/teamData', //团队数据 
    name: 'TeamData',
    component: () => import('@/views/teamData/index'), 
    meta: {
      isShowTabbar: false
    }
  },
  {
    path: '/teamSessionInfo', //场次详情
    name: 'TeamSessionInfo',
    component: () => import('@/views/teamSessionInfo/index'), 
    meta: {
      isShowTabbar: false
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 路由前置守卫
router.beforeEach((to, from, next) => {
  // 有token就表示已经登录
  let token = getToken();
  if (to.path == '/warehouse') {
      // 此时必须要有token
      if (token) {
        next(); // next()去到to所对应的路由界面
      } else {
        Vue.prototype.$toast('请先登录');
        setTimeout(() => {
          next("/login"); // 强制去到"/login"所对应的路由界面
        }, 1000);
      }
  } else {
      // 如果不是去往购物车的路由，则直接通过守卫，去到to所对应的路由界面
      next()
  }
})
export default router
