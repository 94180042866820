const tokenKey='admin-token';
const id='open-id';
export function setToken(token){
	return localStorage.setItem(tokenKey,token)
}
export function getToken(){
	return localStorage.getItem(tokenKey)
}
export function removeToken(){
	return localStorage.removeItem(tokenKey)
}
export function setOpenId(openId){
	return localStorage.setItem(id,openId)
}
export function getOpenId(){
	return localStorage.getItem(id)
}